import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { useLocation } from "@reach/router"

import CompanyDetails from "../../components/about/CompanyDetails"
import Team from "../../components/about/Team"
import ContactFormPage from "../../components/about/Contact/contact"


import { Helmet } from "react-helmet"

import { frCH } from "../../lang/fr-CH"

export default function Home() {
  const location = useLocation()

  const country = "CH"
  const lang = "fr-CH"
  const txt = frCH.about

  return (
    <Layout location={country} path={location.pathname} language={lang}>
      <Seo
        title="DoryGo - Über uns"
        description="Wir helfen Menschen mit chronischen Krankheiten mit Ihren Medikamenten. "
        lang={lang}
      />
      <Helmet>
        <link
          rel="alternate"
          hreflang="fr-CH"
          href="https://dorygo.com/ch-fr/about"
        />
        <link
          rel="alternate"
          hreflang="de-DE"
          href="https://dorygo.com/de-de/about"
        />
        <link
          rel="canonical"
          hreflang="de-CH"
          href="https://dorygo.ch/ch-de/about"
        />
      </Helmet>
      <CompanyDetails txt={txt.CompanyDetails} />
      <Team txt={txt.Team} />
      <ContactFormPage />
    </Layout>
  )
}
